import React, { useEffect, useRef, useCallback } from 'react';
import { Box, Flex, Heading, HStack, Spinner, Text } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import useNoo from 'hooks/useNoo';
import { dropNodeIdPrefix, derivePrefixedNodeId } from 'utils';
import { ContentSubpanel } from 'components/Segments/Styled';
import GroupSelector from 'components/Segments/GroupSelector';
import UserAuth from 'components/User/UserAuth';
import QuestionsList from './QuestionsList';
import OptionsList from './OptionsList';
import OptionDetails from './OptionDetails';
import DecisionSelector from 'components/Segments/DecisionSelector';

const DecisionModule = () => {
  const { groupId: groupIdFromParams, decisionId: decisionIdFromParams } = useParams();
  const history = useHistory();
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'DecisionModule'
  });
  const { isActive, currentDecisionId, getDecisionById } = useDecisions();
  const groupIdSetOnLoad = useRef(false);
  const groupId = derivePrefixedNodeId(groupIdFromParams, 'Groups');

  const { currentNetwork: netname } = useNoo('Decisions');
  const isNewday = netname == 'newday';

  useEffect(() => {
    const addGroupChangeToBrowserHistory = () => {
      const cleanGroupId = currentGroupId && dropNodeIdPrefix(currentGroupId);
      if (cleanGroupId && location.href.indexOf(cleanGroupId) === -1) {
        history.push(`/search_decisions/${cleanGroupId}`);
      }
    };

    if (groupId && groupId !== currentGroupId) {
      if (!groupIdSetOnLoad.current) {
        groupIdSetOnLoad.current = true;
        setGroupId(groupId);
      } else {
        addGroupChangeToBrowserHistory();
      }
    } else if (!groupId && currentGroupId) {
      addGroupChangeToBrowserHistory();
    }
  }, [currentGroupId, groupId, history, setGroupId]);

  const loadDecisionData = useCallback(
    decisionId => {
      if (decisionId) {
        getDecisionById(decisionId);
      }
    },
    [getDecisionById]
  );

  const chooseDecision = useCallback(
    (event, decisionId) => {
      loadDecisionData(decisionId);
    },
    [loadDecisionData]
  );

  return (
    <Box pb='1em'>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <ContentSubpanel width={['100%', '85%']}>
            <Box pb='10px' mb='10px' borderBottom='1px solid #eee'>
              <Heading as='h1' size='md'>
                {isNewday ? <span>Portfolios</span> : <span>Inquiries</span>}
              </Heading>
              <Text>
                Choose a group then {isNewday ? 'a portfolio' : 'an inquiry'}. Vote on existing
                options. Add your own candidate options (not yet editable after submission. Coming
                soon.)
              </Text>
              {groupSelector ? (
                <HStack spacing={10}>
                  <Heading as='h3' size='sm' fontWeight='normal'>
                    Choose a Group
                  </Heading>
                  <GroupSelector label='Choose Group' render='selector' />
                </HStack>
              ) : null}
              {currentGroupId && <QuestionsList groupId={currentGroupId} />}
            </Box>
            <Flex sx={{ gap: '20px' }}>
              {currentDecisionId && <OptionsList />}
              <OptionDetails />
            </Flex>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default DecisionModule;
