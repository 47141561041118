import React, { useMemo } from 'react';
import {
  Box,
  Heading,
  HStack,
  VStack,
  Spinner,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Link
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useDecisions from 'hooks/useDecisions';
import { getOptionTitle, getOptionTotalCharLength } from 'utils/decisions';
import { VoteTotalBox, Divider, Panel } from './Styled';
import { node_normalizer } from 'utils/graphNormalize';
import { truncate_precision } from 'utils';
import { PopOverUser } from 'components/Segments/UserCard';
import Chat from '../Segments/Chat';
import useNoo from 'hooks/useNoo';

import DecisionCreateModal from 'components/Segments/DecisionCreateModal';

const OptionDetails = props => {
  const { isAdmin, currentNetworkData } = useNoo('OptionDetail');
  const isClimate = currentNetworkData?.id == 'climate';
  const { currentDecision, currentDecisionId, currentOption, getOptionVote } = useDecisions();
  const myVote = useMemo(() => {
    return getOptionVote({ decision: currentDecision, option: currentOption });
  }, [currentDecision, currentOption, getOptionVote]);
  let { maxWidth } = props;
  maxWidth = maxWidth || '50%';

  const maxChars = useMemo(() => {
    if (currentOption) {
      return getOptionTotalCharLength([currentOption.total, currentOption.count]);
    }
    return 0;
  }, [currentOption]);

  if (!currentOption) {
    return null;
  }

  const onePop = pid => {
    // display fullname with profile hover
    const person = currentDecision.persons?.[pid] || {};
    const profile = node_normalizer(person?.data?.profile || {});
    if (profile) {
      return (
        <PopOverUser
          key={pid}
          _id={pid}
          node={profile}
          order={['avatar', 'description', 'location', 'bioregion', 'linkedin']}
          onClick={() => {}}
        />
      );
    } else {
      return null;
    }
  };

  const voters = Object.entries(currentOption.votes || {})
    .filter(([pid, w]) => {
      return w > 0;
    })
    .map(([pid, w]) => {
      return (
        <Text key={pid}>
          {onePop(pid)} {truncate_precision(w)}
        </Text>
      );
    });

  const existing = Object.values(currentDecision?.questions).find(
    one => one.data?.title == getOptionTitle(currentOption)
  );
  const existing_net = Object.values(currentDecision?.questions).find(
    one => one.data?.title == getOptionTitle(currentOption) + ' People'
  );

  const heading = getOptionTitle(currentOption) || 'no title';
  const pid = currentOption.data?.data?._id || null; // option is a person record
  const plink = pid ? '/user/' + pid.replace('Persons/', '') : null;

  return (
    <Panel max={maxWidth} margin='0px' padding='10px'>
      <Heading as='h1' size='md' textAlign='left'>
        {plink ? (
          <Link as={RouterLink} to={plink}>
            {heading}
          </Link>
        ) : (
          heading
        )}
      </Heading>
      {currentOption.data?.description ? (
        <Text fontStyle='italic'>{currentOption.data.description}</Text>
      ) : null}
      {isAdmin && isClimate && <Divider />}
      {isAdmin && isClimate && (
        <VStack>
          {!existing && (
            <DecisionCreateModal
              prompt={'Create Subdomains'}
              title={getOptionTitle(currentOption)}
              parent_id={currentDecisionId}
              suggestion={'Suggest subdomains of ' + getOptionTitle(currentOption)}
            />
          )}
          {!existing_net && (
            <DecisionCreateModal
              prompt={'Initiate Expert Network'}
              title={getOptionTitle(currentOption) + ' People'}
              parent_id={currentDecisionId}
              suggestion={'Who knows about ' + getOptionTitle(currentOption) + '?'}
            />
          )}
          <Divider />
        </VStack>
      )}
      <HStack>
        <Text flex='1' fontSize='sm'>
          Total Support for this option:
        </Text>
        <VoteTotalBox maxChars={maxChars} isHighlighted={myVote > 0}>
          {currentOption.total}
        </VoteTotalBox>
      </HStack>
      <HStack>
        <Text flex='1' fontSize='sm'>
          Number of people supporting this option:
        </Text>
        <VoteTotalBox maxChars={maxChars} isHighlighted={myVote > 0}>
          {currentOption.count}
        </VoteTotalBox>
      </HStack>
      <Text>Suggested by {onePop(currentOption.personId)}</Text>
      <Text>Supported by:</Text> {voters}
      <Divider />
      {myVote > 0 ? (
        <HStack>
          <Text flex='1' fontSize='sm'>
            You are supporting this option:
          </Text>
          <VoteTotalBox maxChars={maxChars} isHighlighted={true}>
            {myVote}
          </VoteTotalBox>
        </HStack>
      ) : (
        <Text>You have not voted for this.</Text>
      )}
      <Divider />
      <Text as='strong'>Discuss</Text>
      <Chat chatId={currentOption._id} chatName={currentOption.data.label} />
    </Panel>
  );
};

export const DetailsDrawer = props => {
  const { currentOption } = useDecisions();
  const { isOpen, onOpen, onClose } = props;
  const btnRef = React.useRef();
  const label = currentOption?.data?.label || '';
  // <DrawerOverlay bg='tomato' sx={{ '--opacity': 0.5 }} /> // goes above DrawerContent, but it's opaque
  //    <DrawerHeader>{label}</DrawerHeader>
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      onRightClick={onClose}
    >
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody width='100%' sx={{ margin: '0px', padding: '0px' }}>
          <OptionDetails maxWidth='100%' />
        </DrawerBody>

        <DrawerFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme='blue'>Save</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default OptionDetails;
