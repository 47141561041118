import React from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import { Text, Heading, Box, Flex, VStack } from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import TagCloud from 'components/Segments/TagCloud';
import GreencheckAbout from './AboutPages/GreencheckAbout';
import MarkdownAccordion from 'components/Segments/MarkdownAccordion';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const About = () => {
  const { googleUser: user, currentNetworkData } = useNoo('About');
  const network = currentNetworkData;
  const netname = network.id;
  const markdown = network.about;
  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  var invitationId = params.invite;

  if (invitationId) {
    localStorage.setItem('invitationId', invitationId);
  } else {
    // TBD: check local storage and if there, check it's still valid by reading it
    const stored = localStorage.getItem('invitationId');
    if (stored) {
      /* CHECK INVITE IS LEGIT HERE, AND SET OR REMOVE ON RESPONSE, BUT I DON'T KNOW HOW TO HANDLE THE RESPONSE
      // TODO: if using noo Api here, update to useNooApi() hook
			const aqlQuery = 'return document("' + invitationId + '")';
			localStorage.setItem('invitation', invite);
			nooApi.aqlSearch({user, aqlQuery, setLoading, setResponse});
			*/
      invitationId = stored;
    }
  }

  const display = network?.display;
  const paras = network.welcome;
  const about = null; // network.about;
  let items = [];

  if (netname == 'greencheck') {
    items.push(<GreencheckAbout />);
  } else if (markdown) {
    items.push(<MarkdownAccordion markdown={markdown} />);
  }

  return (
    <VStack sx={{ alignItems: 'left' }}>
      {!user && <SignInOrRegister showHeader={false} />}
      {items}
    </VStack>
  );
};

export default About;
