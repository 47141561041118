import greencheckMarkdown from './homepageMarkdowns/greencheck';
import bestOfNowMarkdown from './homepageMarkdowns/bestofnow';
import blueSwarmMarkdown from './homepageMarkdowns/blueswarm';
import commonsGraphMarkdown from './homepageMarkdowns/commonsgraph';
import shareBnbMarkdown from './homepageMarkdowns/sharebnb';
import aboutNooMarkdown from './homepageMarkdowns/aboutnoo';
import emergeNooMarkdown from './homepageMarkdowns/emerge';
import climateMarkdown from './homepageMarkdowns/climate';
import noonetMarkdown from './homepageMarkdowns/noonet';
import naoMarkdown from './homepageMarkdowns/nao';
import kernelMarkdown from './homepageMarkdowns/kernel';
import perspectivaMarkdown from './homepageMarkdowns/perspectiva';
import stigmastodonMarkdown from './homepageMarkdowns/stigmastodon';
import allwinMarkdown from './homepageMarkdowns/allwin';
import newdayMarkdown from './homepageMarkdowns/newday';
import whoknowsMarkdown from './homepageMarkdowns/whoknows';
import netcoopMarkdown from './homepageMarkdowns/netcoop';
import netcoopAbout from 'components/Segments/AboutPages/NetcoopAbout';
// import greencheckAbout from 'components/Segments/AboutPages/GreencheckAbout';

const other = {
  cij: {
    display: 'Big Mind',
    id: 'cij',
    logo: null,
    logo_name: '/public/images/bigmind.png',
    name_height: 40,
    welcome: ['big mind'],
    bundle: 'planetary'
  },
  ph: {
    display: 'Trim Tab',
    id: 'ph',
    logo: null,
    _logo_name: '/public/images/PHIN.png',
    __logo_name: '/public/images/ph-trans.png',
    logo_name: '/public/images/trim_tab_light.png',
    name_height: 40,
    welcome: [
      '"Make the world work, for 100% of humanity, in the shortest possible time, through spontaneous cooperation, without ecological offense or the disadvantage of anyone."'
    ],
    bundle: 'user_sandbox'
  },
  psyche: {
    display: 'Pollinate!',
    id: 'psyche',
    logo: null,
    _logo_name: '/public/images/psychenet.png',
    logo_name: '/public/images/Pollinate!_red.png',
    name_height: 40,
    welcome: ['Pollinators are essential to life!'],
    bundle: 'planetary',
    headlines: '1432916024358232064' // default list
  }
};
const optional = {};

const all_networks = {
  best_of: {
    display: 'Best of Now',
    tagline: 'Best of Now',
    id: 'best_of',
    logo_name: null,
    _logo: '/public/images/NooNetwork_Mark-200.jpg',
    logo: '/public/images/bestofnow_helv_red2.png',
    __logo: '/public/images/noonet-trans.png',
    ___logo: '/public/images/NooNet-sanchez-light.png',
    _____logo: '/public/images/best-of-now-bw-grey.png', // best-of-now-black-trans.png',
    ____logo: '/public/images/greencheck_cropped.png',
    name_height: 60,
    bundle: 'nodal_sandbox',
    default_group: 'Groups/1c52fcdd-ce82-46f6-84c3-b50d37ab7d9f', // cfee6f1b-7a67-43eb-b214-4b47c06a24d8',
    favicon: '/public/images/bestofnow_red_favicon_50.png',
    welcome: [
      'NoöNet is a community, a commons, a cooperative, a collective intelligence, an editorial network, a token economy, a decentralized financial irrigation system, a governance mechanism...',
      '...and before long an intellectual, financial, and political human superpower.',
      "It's also maybe a solution to fake news, a cooperative alternative to LinkedIn, a global political party, a religion (for tax purposes), a virtual nation.",
      "Why? Because f*ck this sh*t [ https://tinyurl.com/k9bs783c ] ! NoöNet's mission is avoiding dystopia, in favor of the more desireable just, thriving planetary future.",
      "Its means to that end is the formation of an invitation-only global Trust Network of great people. Our terms of service are: don't invite a**holes.",
      "As Facebook has proven, a large collection of humans and their collective interactions are worth billions. How much is NoöNet worth? Pretty much $0 at the moment. But our formula for our collective value is the number of network members times the 'trust density' of their connections. Ask Facebook about their trust density.",
      "If you'd like to participate, browse the Community tab and find someone who might invite you. Once invited, you can invite 10 unique others (i.e. not already in the network.) Please make sure they're great people (see ToS above). ",
      'And participate in our Grand Challenge, by pledging how much you will pitch in monthly when convinced the approach will work.',
      "To help us get to the next level of collective efficacy, chip in here [crowdfund link] in return for the right to buy tokens at the current price when they're available."
    ],
    markdown: bestOfNowMarkdown
  },
  perspectiva: {
    display: 'Perspectiva',
    id: 'perspectiva',
    logo_name: null,
    logo: '/public/images/perspectiva.png',
    name_height: 60,
    bundle: 'nodal_sandbox',
    default_group: 'Groups/603778f4-544a-4a4d-a9cc-b1fca14f5534',
    root_group: 'Groups/603778f4-544a-4a4d-a9cc-b1fca14f5534',
    favicon: 'https://systems-souls-society.com/favicon-32x32.png',
    markdown: perspectivaMarkdown,
    hideInNetworkSelector: true
  },
  emerge: {
    display: 'Emerge',
    pageTitle: 'Emerge Network',
    tagline: 'Emerge as a Networked Autonomous Organization (NAO)',
    id: 'emerge',
    logo_name: null,
    logo:
      'https://www.whatisemerging.com/assets/Emerge_Logo-dba107a9d19d3cfd0f50dbd96434cb7772f2edb24d9c5452b3283dec4135ebfe.png',
    name_height: 60,
    bundle: 'nodal_sandbox', // 'spacetime_0', //
    default_group: 'Groups/25453ab6-3203-4fee-94c1-159b4faadac5', // 'cfee6f1b-7a67-43eb-b214-4b47c06a24d8',
    root_group: 'Groups/603778f4-544a-4a4d-a9cc-b1fca14f5534', // Emerge
    welcome: [],
    markdown: emergeNooMarkdown,
    favicon: '/public/images/capitalE_50_66.png'
  },
  sharebnb: {
    display: 'StayForward',
    id: 'sharebnb',
    logo: '/public/images/SF_stayforward.png', // '/public/images/share_mark_drawn_alpha.png',
    logo_name: '/public/images/stayforward.png', // '/public/images/stayforwardhoneydew.png', // share_name_logo_orange_alpha.png',
    tagline: 'Stay it forward',
    welcome: [],
    markdown: shareBnbMarkdown,
    root_group: 'Groups/23779d15-6688-498b-85d6-616b59435fee',
    bundle: 'data_sandbox', // 'test'
    hideInNetworkSelector: true
  },
  commonsgraph: {
    display: 'CommonsGraph',
    id: 'commonsgraph',
    favicon: '/public/images/CommonsGraph_200.png', // '/public/images/share_mark_drawn_alpha.png',
    _logo: '/public/images/OceansGivingNetwork.png',
    logo: '/public/images/CommonsGraph_200.png', // '/public/images/share_mark_drawn_alpha.png',
    __logo:
      'https://secureservercdn.net/198.71.233.138/xxj.064.myftpupload.com/wp-content/uploads/2018/06/logo_MWI-1-e1528083690364.jpg',
    logo_name: '', // '/public/images/stayforwardhoneydew.png', // share_name_logo_orange_alpha.png',
    tagline: 'Own the Platform',
    welcome: [],
    markdown: commonsGraphMarkdown,
    bundle: 'test',
    hideInNetworkSelector: true
  },

  greencheck: {
    id: 'greencheck',
    display: 'GreenCheck',
    favicon: '/public/images/greencheck_cropped.png',
    logo: '/public/images/greencheck_cropped.png',
    logo_name: '', // '../../public/public/images/stayforwardhoneydew.png', // share_name_logo_orange_alpha.png',
    tagline: null,
    welcome: [],
    markdown: greencheckMarkdown,
    // _about: greencheckAbout,
    bundle: 'nodal_sandbox',
    obfuscate: false,
    _root_group: 'Groups/faae1601-1f5d-4a0f-a542-6a1726b6343e'
  },

  blueswarm: {
    display: 'BlueSwarm',
    tagline: 'Because dystopia is not ok',
    id: 'blueswarm',
    logo_name: null,
    logo: '/public/images/BlueSwarm.png',
    _logo: '/public/images/greencheck_cropped.png', // global_swarming.png', // climate_swarm_futura.png',
    name_height: 40,
    welcome: ["We're not asking politely anymore."],
    bundle: 'nodal_sandbox', // 'planetary',
    markdown: blueSwarmMarkdown,
    root_group: 'Groups/f086f344-6062-42ee-adfe-c49349dab9b3',
    favicon: '/public/images/BlueSwarm_200.png'
  },
  ph: {
    display: 'StigMastodon',
    id: 'ph',
    logo_name: null,
    _logo_name: '/public/images/PHIN.png',
    __logo_name: '/public/images/ph-trans.png',
    ___logo_name: '/public/images/trim_tab_light.png',
    ____logo: '/public/images/regentwitter.png',
    logo: '/public/images/stigmastodon.png',
    favicon: '/public/images/stigmast_icon.png',
    name_height: 40,
    welcome: [
      '"Make the world work, for 100% of humanity, in the shortest possible time, through spontaneous cooperation, without ecological offense or the disadvantage of anyone." - R. Buckminster Fuller'
    ],
    bundle: 'user_sandbox',
    root_group: 'Groups/453230486',
    markdown: stigmastodonMarkdown,
    hideInNetworkSelector: true // allow stigmastodon.net to work but don't show in pulldown
  },
  demo: {
    display: 'Demo',
    id: 'demo',
    _logo: '/public/images/BlueSwarm.png',
    __logo: '/public/images/noonet-trans.png',
    logo: '/public/images/NooNetwork_Mark-200.jpg',
    logo_name: null,
    _logo_name: '/public/images/ooNetworkMontserrat.png',
    ___logo_name: '/public/images/NooNet-sanchez-light.png',
    ____logo_name: '/public/images/best-of-now-bw-grey.png', // best-of-now-black-trans.png',
    name_height: 40,
    favicon: '/public/images/NooNetwork_Mark-200.jpg',
    welcome: [
      'NoöNet is a community, a commons, a cooperative, a collective intelligence, an editorial network, a token economy, a decentralized financial irrigation system, a governance mechanism...',
      '...and before long an intellectual, financial, and political human superpower.',
      "It's also maybe a solution to fake news, a cooperative alternative to LinkedIn, a global political party, a religion (for tax purposes), a virtual nation.",
      "Why? Because f*ck this sh*t [ https://tinyurl.com/k9bs783c ] ! NoöNet's mission is avoiding dystopia, in favor of the more desireable just, thriving planetary future.",
      "Its means to that end is the formation of an invitation-only global Trust Network of great people. Our terms of service are: don't invite a**holes.",
      "As Facebook has proven, a large collection of humans and their collective interactions are worth billions. How much is NoöNet worth? Pretty much $0 at the moment. But our formula for our collective value is the number of network members times the 'trust density' of their connections. Ask Facebook about their trust density.",
      "If you'd like to participate, browse the Community tab and find someone who might invite you. Once invited, you can invite 10 unique others (i.e. not already in the network.) Please make sure they're great people (see ToS above). ",
      'And participate in our Grand Challenge, by pledging how much you will pitch in monthly when convinced the approach will work.',
      "To help us get to the next level of collecitve efficacy, chip in here [crowdfund link] in return for the right to buy tokens at the current price when they're available."
    ],
    markdown: `
Welcome to Demo.
             
`,
    bundle: 'data_sandbox'
  },

  noonet: {
    display: 'NooNet',
    tagline: '',
    id: 'noonet',
    logo: '/public/images/NooNetwork_Mark-200.jpg',
    logo_name: null,
    favicon: '/public/images/NooNetwork_Mark-200.jpg',
    name_height: 40,
    bundle: 'nodal_sandbox',
    markdown: naoMarkdown
  },

  psyche: {
    display: 'Pollinate!',
    id: 'psyche',
    logo: null,
    _logo_name: '/public/images/psychenet.png',
    logo_name: '/public/images/Pollinate!_red.png',
    name_height: 40,
    welcome: ['Pollinators are essential to life!'],
    bundle: 'planetary',
    headlines: '1432916024358232064' // default list
  },
  keep_out: {
    display: 'KeepOut',
    id: 'keepout',
    logo: null,
    logo_name: '/public/images/psychenet.png',
    _logo_name: '/public/images/Pollinate!_red.png',
    name_height: 40,
    welcome: ['Off Limits'],
    bundle: 'finland_test' // 'keep_out' //
  },
  allwin: {
    display: 'All Win Party',
    tagline: 'Toward a future that works for all',
    id: 'climate',
    logo_name: null,
    logo: '/public/images/climate_swarm_futura.png',
    favicon: '/public/images/climate_swarm_futura.png',
    name_height: 40,
    welcome: ["We're not asking politely anymore."],
    bundle: 'nodal_sandbox',
    root_group: 'Groups/c0d3dec9-9154-4a07-923a-abf1ed93b70a',
    markdown: allwinMarkdown
  },
  climate: {
    display: 'Climate Swarm',
    tagline: 'Taking Charge',
    id: 'climate',
    logo_name: null,
    logo: '/public/images/climateMajoritySwarm.png',
    favicon: '/public/images/climateMajoritySwarm.png',
    name_height: 40,
    welcome: ["We're not asking politely anymore."],
    bundle: 'test', // 'nodal_sandbox',
    // root_group: 'Groups/c0d3dec9-9154-4a07-923a-abf1ed93b70a',
    root_group: 'Groups/d79e976b-4dba-4e82-9078-16056b2fb91f',
    default_group: 'Groups/d79e976b-4dba-4e82-9078-16056b2fb91f',
    markdown: climateMarkdown
  },
  kernel: {
    display: 'Kernel',
    tagline: 'Learning Together',
    id: 'kernel',
    logo_name: null,
    logo: 'https://www.kernel.community/public/images/kernel_lp.png',
    favicon: '/public/images/kernel_favicon.png',
    name_height: 40,
    welcome: ['Learning Together'],
    bundle: 'nodal_sandbox',
    default_group: 'Groups/1eb1ab37-8225-4449-95bc-ee8534cfbf78',
    root_group: 'Groups/1eb1ab37-8225-4449-95bc-ee8534cfbf78',
    markdown: kernelMarkdown,
    tab_order: ['bookmarks', 'share', 'inquiries', 'network']
  },
  nao: {
    display: 'Noo NAO',
    tagline: '', // 'Networked Autonomous Organisms',
    id: 'nao',
    logo_name: null,
    logo: '/public/images/NooNao.png',
    favicon: '/public/images/NooNao.png',
    name_height: 40,
    welcome: ['Networked Autonomous Organisms'],
    bundle: 'nodal_sandbox',
    _root_group: 'Groups/e2ddcb90-225b-4df1-a080-c850af1c16f2',
    markdown: naoMarkdown
  },
  newday: {
    display: 'NewDay Impact',
    id: 'newday',
    logo_name: null,
    logo: '/public/images/newday_logo.png',
    name_height: 60,
    bundle: 'spacetime_0',
    default_group: 'Groups/30916456-16e5-4b7b-98d9-0b266140cb5e',
    root_group: 'Groups/603778f4-544a-4a4d-a9cc-b1fca14f5534',
    favicon:
      'https://i0.wp.com/newdayimpact.com/wp-content/uploads/2021/03/cropped-a3h7KoAE.jpg?fit=32%2C32&ssl=1',
    markdown: newdayMarkdown,
    hideInNetworkSelector: true
  },
  whoknows: {
    display: 'Who Knows',
    id: 'whoknows',
    logo_name: null,
    logo: '/public/images/whoknows.png',
    name_height: 60,
    bundle: 'nodal_sandbox',
    default_group: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1',
    _root_group: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1',
    favicon: '/public/images/whoknows.png',
    markdown: whoknowsMarkdown
  },
  netcoop: {
    display: 'Network Cooperative',
    id: 'netcoop',
    logo_name: null,
    logo: 'https://standingwave.net/img/NN/regen_network_cooperative_cooperative.png',
    name_height: 60,
    bundle: 'data_sandbox',
    _bundle: 'spacetime_0',
    default_group: 'Groups/f1b51fd0-a1af-4dbb-aa43-e248baf9d151',
    favicon: 'https://standingwave.net/img/NN/regen_network_cooperative_cooperative.png',
    markdown: netcoopMarkdown,
    about: netcoopAbout,
    hideInNetworkSelector: true
  },
  portable: {
    display: 'Portable Communities',
    id: 'portable',
    logo_name: null,
    logo: 'https://standingwave.net/img/NN/PortableCommunities_cooperative.png',
    name_height: 60,
    bundle: 'nodal_sandbox',
    _bundle: 'spacetime_0',
    default_group: 'Groups/f1b51fd0-a1af-4dbb-aa43-e248baf9d151',
    favicon: 'https://standingwave.net/img/NN/PortableCommunities_cooperative.png',
    markdown: netcoopMarkdown,
    obfuscate: false,
    root_group: 'Groups/faae1601-1f5d-4a0f-a542-6a1726b6343e',
    about: netcoopAbout,
    hideInNetworkSelector: true
  }
};

const default_groups = {
  data_sandbox: 'cfee6f1b-7a67-43eb-b214-4b47c06a24d8',
  nodal_sandbox: '603778f4-544a-4a4d-a9cc-b1fca14f5534', // 'dd2b2d10-c451-4509-afff-bd17bd7e2676',
  user_sandbox: '153313352',
  planetary: '0266b292-ee88-40b5-8009-45b7430c69b2',
  test: null
};

const networks = {};

let enabled = ['commonsgraph', 'best_of', 'sharebnb', 'ph', 'blueswarm', 'greencheck', 'demo']; //
// enabled = ['best_of', 'sharebnb']
enabled = [
  'kernel',
  'noonet',
  'emerge',
  'best_of',
  'blueswarm',
  'climate',
  'greencheck',
  'nao',
  'commonsgraph',
  'perspectiva'
]; // 'commonsgraph', 'demo',
enabled = [
  // IMPORTANT! Removing here breaks standalone sites like Emerge, climate
  'climate',
  'emerge',
  'best_of',
  'sharebnb',
  'nao',
  'greencheck',
  // 'ph', // stigmastodon
  // 'commonsgraph',
  'whoknows',
  'newday',
  'netcoop',
  'portable'
];

enabled.forEach(one => {
  networks[one] = all_networks[one];
  // Following was cause the groupSelector to always go to the default for the bundle
  // networks[one].default_group = networks[one].default_group || default_groups[networks[one].bundle];
});

const connective =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoibm9vLW5ldHdvcmtzLW1haW4uaGVyb2t1YXBwLmNvbSIsImRvbWFpbiI6Im5vby1uZXR3b3Jrcy1tYWluLmhlcm9rdWFwcC5jb20iLCJlbnYiOiJub28tbmV0d29ya3MtbWFpbi5oZXJva3VhcHAuY29tIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0Nzk4MDkyMTYsImlhdCI6MTYzODkwMzYxNX0.Db5DxKqe-TMFGeLcqDTuTfieeptMPMopQZxU6uMQGdg';

export { networks, aboutNooMarkdown as about, connective };

export const getNetworkData = netName => {
  return (netName && networks[netName]) || null;
};

export const isValidNetwork = netName => {
  return !!(netName && networks[netName]?.bundle);
};

export const getNetworkBundle = netName => {
  return isValidNetwork(netName) && networks[netName].bundle;
};

export const getNetworks = () => {
  return networks;
};
