import React, { useMemo } from 'react';
import _ from 'lodash';
import { Select } from '@chakra-ui/react';

const GenericSelector = props => {
  const { pairs, callback, selected, width = '300px' } = props; // id,display pairs; id only if display = id.capitalize

  const options = useMemo(() => {
    const make_option = (value, display) => {
      if (display) {
        return (
          <option value={value} isdisabled='true' key={value}>
            {display}
          </option>
        );
      }
      return null;
    };

    if (pairs) {
      let opts = [];
      opts = opts.concat(
        _.filter(
          _.map(pairs, pair => {
            pair = typeof pair == 'string' ? [pair, _.capitalize(pair)] : pair;
            return make_option(pair[0], pair[1]);
          }),
          one => {
            return one ? true : false;
          }
        )
      );
      if (opts.length > 0) {
        return opts;
      }
    }
  }, [pairs]);

  const is_selector = true;
  return (
    <div>
      {is_selector && (
        <Select
          onChange={callback}
          value={selected || ''}
          w={width}
          bg='#fff'
          _focus={{ outline: '0' }}
        >
          {options}
        </Select>
      )}
    </div>
  );
};

export default GenericSelector;
