import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  Input,
  Button,
  FormLabel,
  Textarea,
  Spinner,
  Link,
  Text,
  Flex,
  HStack,
  Checkbox,
  Heading,
  Image,
  IconButton
} from '@chakra-ui/react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import useQuery from 'utils/useQuery';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

// import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Results from './Results/Bookmarks';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import _ from 'lodash';
import TimeChooser from 'components/Segments/TimeChooser';
import GroupSelector from 'components/Segments/GroupSelector';
import PeopleSelector from 'components/Segments/PeopleSelector';
import AboutPopover from 'components/Segments/AboutPopover';
import BookmarkletDraggable from 'components/Segments/BookmarkletDraggable';
import { getNooBundle } from 'redux/actions/nooApi';
import { ContentSubpanel } from 'components/Segments/Styled';
import useMultiDomain from 'hooks/useMultiDomain';
import useGroups from 'hooks/useGroups';

const FieldChooser = props => {
  const checks = props.options.map(f => (
    <Checkbox
      key={f}
      size='sm'
      type='checkbox'
      value={f}
      name={f}
      isChecked={_.includes(props.fields, f)}
      onChange={props.updateFields}
    >
      <Text as='span' fontSize={['md', 'sm', 'xs']}>
        {f}
      </Text>
    </Checkbox>
  ));
  return checks;
};

const FilterDivider = styled.div`
  border-left: 1px solid #ddd;
  height: 1.7em;
  width: 1px;
`;

const SearchBookmarks = props => {
  // const dispatch = useDispatch();
  const { isAuthorized, currentNetworkData } = useNoo('SearchBookmarks');
  const { currentGroupId, groupSelector } = useGroups({ from: 'SearchBookmarks' });

  const qparams = useQuery();
  let { query, since, groups: groups_in, user } = useQuery();
  const network = currentNetworkData;
  const default_group = network.default_group;
  // groups_in = groups_in || network?.default_group || '';
  const { nooCommandDirect } = useNooApi();

  const default_list = network.headlines || '105092773';
  const [response, setResponse] = useState('{}');
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);

  // var params = new URLSearchParams(useLocation().search);

  const [searchFilter, setSearchFilter] = useState(query || '');
  const [searchSince, setSearchSince] = useState(parseInt(since || '1000'));
  const [searchUser, setSearchUser] = useState(parseInt(null));
  const all_fields = ['title', 'description', 'tags'];
  const [searchFields, setSearchFields] = useState(all_fields);
  const [groupId, setGroupId] = useState(null);
  const [groups, setGroups] = useState(groups_in ? groups_in.split(',') : null);
  const [modifiedFilters, setModifiedFilters] = useState(false);
  const autoSearch = useRef(null);
  const headlinesSearchIsProcessing = useRef(null);
  const history = useHistory();

  const { domainConfig } = useMultiDomain();
  const { showBetaNotes } = domainConfig;

  const hasBundle = getNooBundle();

  const get_ts = since => {
    const now = new Date();
    return now.getTime() / 1000 - since * 86400;
  };

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [responseRef]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      setGroupId(groups[0]);
    }
  }, [groups]);

  // useEffect(() => {
  //   if (nooCommandResponse?.command === 'aql') {
  //     setResponse(nooCommandResponse.data);
  //   }
  // }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  const onUpdateFilter = e => {
    setSearchFilter(e.target.value);
    setModifiedFilters(true);
  };

  const searchPayload = useMemo(() => {
    const ts = get_ts(searchSince);
    return {
      ts,
      text: searchFilter,
      fields: searchFields,
      groups,
      user: searchUser
    };
  }, [searchSince, searchFilter, searchFields, groups, searchUser]);

  const url_params = useCallback(() => {
    let todo = { query: searchFilter, groups: groups ? groups[0] : null, since: searchSince };
    if (searchUser) todo.user = searchUser;
    let params = '';
    Object.entries(todo).forEach(pair => {
      if (pair[1]) {
        params += [pair[0], encodeURIComponent(pair[1])].join('=') + '&';
      }
    });
    return params;
  }, [searchFilter, groups, searchSince, searchUser]);

  const getUrlParts = useCallback(() => {
    const _URL = new URL(location.href);
    const path = _URL.pathname;
    let url = [_URL.protocol, _URL.host].join('//') + path;
    const params = url_params();
    return [url, path, params];
  }, [url_params]);

  const createLink = () => {
    let [url, path, params] = getUrlParts();
    if (params.length > 0) {
      url += '?' + params;
    }
    navigator.clipboard.writeText(url).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!
      alert('Copied to clipboard: ' + url);
    });
  };

  const onCallSearchFilter = useCallback(
    (e, payloadOverride) => {
      if (isAuthorized) {
        const payload = {
          query_name: 'aqlSearchBookmarks',
          data: payloadOverride || searchPayload
        };

        const loading = () => {};
        const responseHandler = response => {
          headlinesSearchIsProcessing.current = false;
          setResponse(response?.result?.[0]);
        };
        const responseError = error => {
          headlinesSearchIsProcessing.current = false;
          console.error('Error getting headlines', error);
          //reject(error);
        };
        headlinesSearchIsProcessing.current = true;
        nooCommandDirect('aql', {
          payload,
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
        setModifiedFilters(false);
        let [url, path, params] = getUrlParts();
        history.replace([path, params].join('?'));
      }
    },
    [isAuthorized, searchPayload, nooCommandDirect, history, getUrlParts]
  );

  useEffect(() => {
    // cause search on load
    if (isAuthorized && hasBundle && !autoSearch.current) {
      onCallSearchFilter();
      autoSearch.current = true;
    }
  }, [isAuthorized, onCallSearchFilter, autoSearch, hasBundle]);

  const toggleRadio = e => {
    setSearchSince(parseInt(e.target.value));
    setModifiedFilters(true);
  };
  const updateFields = e => {
    const checked = e.target.checked;
    const val = e.target.value;
    let current = [...searchFields];
    if (checked && !_.includes(current, val)) {
      current.push(val);
    }
    if (!checked) {
      current = current.filter(one => one != val);
    }
    if (current.length > 0) {
      setSearchFields(current);
      setModifiedFilters(true);
    }
  };
  const onSelectGroup = (event, groupId) => {
    setGroups(groupId && groupId.toLowerCase() !== 'any' ? [groupId] : null);
    setGroupId(groupId && groupId.toLowerCase() !== 'any' ? groupId : null);
    setModifiedFilters(true);
  };

  const onSelectUser = user => {
    // console.log('Selected user', user);
  };
  const resetFilters = event => {
    setGroups(null);
    setSearchSince(60);
    setSearchUser(null);
    setSearchFilter(null);
    setModifiedFilters(false);
    onCallSearchFilter(null, {
      ...searchPayload,
      text: null,
      groups: null
    });
  };

  const disabledFilterButton = !modifiedFilters || headlinesSearchIsProcessing?.current;

  /*   <HStack>
  <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
    People:
  </Text>
  <Text
    fontSize={['md', 'sm', 'xs']}
    fontWeight={['normal', 'normal', 'normal']}
    textDecoration={['italic']}
  >
    Coming soon...
  </Text>
</HStack>
<FilterDivider /> */

  const ShareIcon = FontAwesomeIcon({
    icon: ['fad', 'share-alt'],
    name: 'share',
    style: { marginTop: '2px', marginInlineEnd: 0 }
  });
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <HStack>
            <Heading as='h2' fontSize='2xl' textAlign={['center', 'left']} flex='1'>
              Our bookmarks
            </Heading>
            <HStack>
              <BookmarkletDraggable />
            </HStack>
          </HStack>
          {showBetaNotes ? (
            <Box
              width={['100%', '85%', '65%']}
              py={['10px', undefined]}
              my={['10px', undefined]}
              mx={['10px', 'auto']}
              borderTop={['1px solid #ddd', undefined]}
              borderBottom={['1px solid #ddd', undefined]}
            >
              <AboutPopover />
            </Box>
          ) : null}
          <ContentSubpanel width={['100%', '85%']}>
            {headlinesSearchIsProcessing?.current && (
              <Box style={{ float: 'left' }}>
                <Spinner />
              </Box>
            )}
            {groupSelector ? (
              <>
                <HStack>
                  <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                    Group:
                  </Text>
                  <GroupSelector
                    callback={onSelectGroup}
                    label={'Choose Group'}
                    selected={groupId}
                    render={'selector'}
                    width='150px'
                    showAnyOption={true}
                  />
                </HStack>
              </>
            ) : null}

            <HStack
              wrap='wrap'
              spacing='12px'
              paddingTop='10px'
              marginTop='10px'
              borderTop='1px solid #ddd'
            >
              <Heading as='h3' size='sm'>
                Filters
              </Heading>
              <IconButton
                aria-label='Search database'
                title='Share this search'
                onClick={createLink}
                icon={ShareIcon}
              />
              <Box>
                <Button
                  size='xs'
                  cursor='pointer'
                  onClick={onCallSearchFilter}
                  disabled={disabledFilterButton}
                  backgroundColor={disabledFilterButton ? undefined : 'cyan.500'}
                  color={disabledFilterButton ? undefined : 'white'}
                >
                  Apply
                </Button>
              </Box>
              <FilterDivider />
              <Box>
                <Button
                  onClick={resetFilters}
                  size='xs'
                  cursor='pointer'
                  disabled={disabledFilterButton}
                  backgroundColor={disabledFilterButton ? undefined : 'pink.500'}
                  color={disabledFilterButton ? undefined : 'white'}
                >
                  Reset
                </Button>
              </Box>

              {true && (
                <>
                  <HStack space='2'>
                    <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                      Text:
                    </Text>
                    <Input
                      height={['30px', '30px', '24px']}
                      width={['180px', '200px', '300px']}
                      type='text'
                      id='filter'
                      name='filter'
                      bgColor='white'
                      placeholder='search term'
                      defaultValue={searchFilter}
                      onChange={onUpdateFilter}
                    />
                  </HStack>
                  <FilterDivider />
                </>
              )}
              <HStack space='2'>
                <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                  Time Range:
                </Text>
                <TimeChooser
                  value={searchSince}
                  handleChoice={toggleRadio}
                  type='select'
                  controlOnly
                  size={'sm'}
                  width={['200px', '150px', '100px']}
                />
              </HStack>
              <FilterDivider />

              <HStack space='2' flex='1'>
                <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                  Look In:
                </Text>
                <FieldChooser
                  options={all_fields}
                  fields={searchFields}
                  updateFields={updateFields}
                />
              </HStack>
            </HStack>

            {/*
              <Box className='debug'>
                <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
                <Textarea
                  ref={responseRef}
                  id='grpcResponse'
                  name='grpcResponse'
                  style={{ height: responseHeight }}
                  readOnly={true}
                  value={formatResponse(response)}
                />
              </Box>
              */}

            <Box className='whitebox_headlines' bgColor='#fff'>
              {autoSearch.current &&
                !headlinesSearchIsProcessing?.current &&
                response &&
                !response.documents?.length && (
                  <Box textAlign='center'>
                    <Heading as='h3'>No Headlines found</Heading>
                    <Text>Try changing your filters, or </Text>
                    <Button onClick={resetFilters} fontSize='lg' cursor='pointer'>
                      Reset
                    </Button>
                  </Box>
                )}
              {response?.documents?.length ? <Results data={response} /> : null}
            </Box>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default SearchBookmarks;
