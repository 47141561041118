import React from 'react';
import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Heading, HStack, Text, VStack, Tooltip, Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import useGroups from 'hooks/useGroups';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import NetworkTabs from 'components/Networks/NetworkTabs';
import { dropNodeIdPrefix } from 'utils';
import noo from 'api';
// import { venn_communities } from 'shared/data/venn_mock';

/* FOR r IN Resources
  FOR p IN Persons
    FILTER 'sharebnb' in p.subnets && r.personId == p._id 
    RETURN r */

const CommunityModule = props => {
  const { nooUser, currentNetworkData, currentNetworkDataRaw } = useNoo('CommunityModule');
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    getCommunity,
    currentNetworkRaw
  } = usePeopleNetwork();
  var params = new URLSearchParams(useLocation().search);
  const inGroupId = params.get('group');
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [groupData, setGroupData] = useState(null);
  const [visibleToMe, setVisibleToMe] = useState([]);
  const network = currentNetworkData;
  const netname = network.id;
  const [groupId, setGroupId] = useState(props.groupId);
  const { currentGroupName, currentGroupId, groups } = useGroups({
    from: 'ShowGroup'
  });
  // console.log('ven', venn_communities);

  const history = useHistory();
  const boxSx = {
    border: '1px solid #ccc',
    flex: '1 1 auto',
    padding: '10px'
  };
  const makeUrl = primary => {
    let url = `/community/?${primary}`;
    return url;
  };

  useEffect(() => {
    if (inGroupId && nooUser && inGroupId != groupId) {
      setGroupId(inGroupId);
      getCommunity({
        groupId: inGroupId,
        personId: nooUser?.person?._id,
        direction: 'any',
        limit: 200
      });
    }
  }, [getCommunity, groupId, inGroupId, nooUser]);

  useEffect(() => {
    if (groupId && groups) {
      const gdata = _.find(groups, one => {
        return one._id == groupId;
      });
      if (gdata) {
        setGroupData(gdata);
        setGroupName(gdata.data?.fullname);
        setDescription(gdata.data?.description);
      }
    }
  }, [groupId, groups]);

  const clickFriend = e => {
    console.log('click user', e);
  };

  const onChecked = e => {
    console.log('check user', e);
  };

  const disabled = false;
  const onShare = event => {
    const g_edges = nooUser?.group_edges;
    let these;
    if (g_edges) {
      these = _.filter(g_edges, pair => {
        return pair[0] == groupId;
      });
    }
    console.log('these', these);
  };

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <VStack sx={boxSx}>
            <Heading as='h1'>{groupName}</Heading>
            {description && <Text>{description}</Text>}
          </VStack>
          <VStack sx={boxSx}>
            <Heading size='md'>Tools</Heading>
            <Text>
              Pink rows are members only visible to you. If you share your connections in this
              community, others will be able to see them. Green are visible to group members. Yellow
              are visible to all.
            </Text>
            <HStack>
              {' '}
              <Tooltip label={'Coming soon'}>
                <Button colorScheme='teal' disabled={false}>
                  Add my connections.
                </Button>
              </Tooltip>
              <Tooltip label={'Coming soon'}>
                <Button colorScheme='teal' disabled={false}>
                  Delete my connections.
                </Button>
              </Tooltip>
              <Button
                variant='pill'
                sx={{ height: '30px', padding: '3px' }}
                onClick={onShare}
                disabled={disabled}
              >
                {'Turn on'}
              </Button>
            </HStack>
          </VStack>
          {graph && (
            <NetworkTabs
              graph={graph}
              maxNodes={200}
              onChecked={onChecked}
              clickFriend={clickFriend}
              default_tab={0}
            />
          )}
        </Box>
      </UserAuth>
    </Box>
  );
};

export default CommunityModule;
