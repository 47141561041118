/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
  Tooltip,
  useDisclosure,
  Select,
  useToast
} from '@chakra-ui/react';
import useDecisions from 'hooks/useDecisions';
import useNoo from 'hooks/useNoo';
import useGroups from 'hooks/useGroups';
import { objectSort, multiSort, truncate_precision, median } from 'utils';
import {
  getOptionTitle,
  // getOptionTallyType,
  getOptionTotalCharLength,
  addTalliesToOptions
} from 'utils/decisions';
import FontAwesomeLoader from 'utils/FontAwesomeLoader';
import OptionSlider from './OptionSlider';
import { VoteTotalBox, Divider, Panel } from './Styled';
import _ from 'lodash';
import OptionDetails, { DetailsDrawer } from './OptionDetails';
import PeopleSelector from 'components/Segments/PeopleSelector';

// import SimpleCloud from '../Segments/SimpleCloud';

const OptionsList = props => {
  const minWidth = props.width || '45%';
  const maxWidth = props.width || '50%';
  const size = props.headersize || 'sm';
  const {
    currentDecision,
    currentOptionId,
    isActive,
    decisions,
    currentOption,
    addOptionError,
    getOptionById,
    getOptionVote,
    addAnswerToCurrentDecision,
    addOptionToCurrentDecision
  } = useDecisions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { currentGroupId } = useGroups({ from: 'OptionsList' });
  const { nooUser, currentNetworkData } = useNoo('OptionsList');
  const network = currentNetworkData;
  const netname = network.id;
  const isClimate = netname == 'climate';
  const isWho = netname == 'whoknows';
  const useDrawer = isClimate || isWho;
  const my_groups = useMemo(() => {
    return nooUser ? _.union(nooUser?.member, nooUser?.owner) : [];
  }, [nooUser]);

  const disabled = useMemo(() => {
    return !my_groups.includes(currentGroupId);
  }, [my_groups, currentGroupId]);
  const [newOptionData, setNewOptionData] = useState({});
  const [maxAnswer, setMaxAnswer] = useState(1);
  const [avgAnswer, setAvgAnswer] = useState(1);
  const [decisionType, setDecisionType] = useState(currentDecision?.question?.data?.type);
  const [cloud, setCloud] = useState([]);
  const qprompt = useMemo(() => currentDecision?.question?.data?.prompt || 'Suggest an option', [
    currentDecision
  ]);
  const sortOptions = {
    'score ⌄ (default)': '-total,sort_label',
    'score ^': 'total,sort_label',
    'created ^': 'createDate',
    'created ⌄': '-createDate',
    'name ^': 'sort_label',
    'name v': '-sort_label'
  };
  const [sortOrder, setSortOrder] = useState(Object.keys(sortOptions)[0]);

  const SortSelector = props => {
    return (
      <Select
        onChange={props.onChange}
        padding={'1px '}
        sx={{ backgroundColor: '#fff', height: '30px', paddingTop: '1px' }}
        defaultValue={sortOrder}
      >
        {Object.entries(sortOptions).map((pair, i) => {
          return (
            <option value={pair[0]} key={i}>
              {pair[0]}
            </option>
          );
        })}
      </Select>
    );
  };
  useEffect(() => {
    if (addOptionError) {
      toast({
        title: 'Problem',
        description: addOptionError,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  }, [addOptionError, toast]);

  useEffect(() => {
    if (currentDecision) setDecisionType(currentDecision.question?.data?.type);
  }, [currentDecision, setDecisionType]);

  const updateNewOptionData = e => {
    const myOptionData = { ...newOptionData };
    myOptionData[e.target.name] = e.target.value;
    setNewOptionData(myOptionData);
  };

  const onSortChange = e => {
    setSortOrder(e.target.value);
  };

  const options = useMemo(() => {
    if (currentDecision) {
      let maxx = 1;
      let avg = 0;
      const my_answer = currentDecision?.my_answer?.[0];
      let mediann = 1;
      if (my_answer) {
        const votes = my_answer.data?.vote || {};
        let vals = Object.values(votes).filter(v => v > 0);
        const k = vals.length;
        maxx = _.max(vals) || 1;
        avg = k > 0 ? _.sum(vals) / k : 1;
        mediann = k > 0 ? median(vals) : 1;
        // console.log('k', k, 'avg', avg, 'med', mediann, 'vals', vals);
      }
      setMaxAnswer(maxx); // used for pegging top answers to 1 on slider
      setAvgAnswer(mediann);
      return addTalliesToOptions(currentDecision, currentDecision.options);
    }
    return [];
  }, [currentDecision]);

  useEffect(() => {
    let new_cloud = [];
    if (options) {
      const tallies = _.filter(options, opt => {
        return opt.total ? true : false;
      });
      if (tallies.length > 0) {
        _.forEach(options, function (option) {
          let word = {
            value: option.data.label,
            count: option.total || 0,
            _id: option._id
          };
          new_cloud.push(word);
        });
        if (new_cloud.length > 0) {
          setCloud(new_cloud);
        }
      }
    }
  }, [options]);
  const maxCharsInOptionTotals = useMemo(() => {
    if (options?.length) {
      return getOptionTotalCharLength(options.map(option => truncate_precision(option.total)));
    }
    return 1;
  }, [options]);

  const loadOptionData = useCallback(
    optionId => {
      if (useDrawer) onOpen();
      if (optionId) {
        getOptionById(optionId);
      }
    },
    [getOptionById, onOpen, useDrawer]
  );

  if (isActive && decisions?.length) {
    return <Spinner />;
  }

  /*   if (currentDecision) {
    // !options?.length
    console.log('no current decision'); // options
    return null;
  } */
  const handleCheckboxVote = (event, option) => {
    const vote = event.target.value > 0 ? 0 : avgAnswer; // binary on or off for checkbox click
    // console.log('check', vote, event.target.value, maxAnswer, avgAnswer);
    addAnswerToCurrentDecision({ optionId: option._id, vote });
  };

  const handleSliderChange = (option, voteValue) => {
    // console.log('handleSliderChange', option._id, voteValue);
    addAnswerToCurrentDecision({ optionId: option._id, vote: voteValue * maxAnswer });
    // need to add in the vote normalizing stuff here

    // IMPLEMENTATION NOTES: thinking we just round trip and send this off to the server after normalizing?
    // OR, we have to update the local currentDecision.answers[0] to have the normalized values
    // both will work for the UI, then we can either have an explicit save button,
    // or a listener of some kind that will push the adjusted normalized votes/answer data
    // when question changes or idle time breached

    // tech approach
    // after normalization:
    // 1. new hook function updateAnswersToCurrentDecision
    // 2. calls into actions/decisions to call AQL to update votes
    //    look at addAnswerToDecision() to see how it updates the question data with aql call after success
    // 3. reducer will pick up the refresh call by itself, and UI will update
  };

  const handleAddOption = () => {
    let { label, description } = newOptionData;
    label = label ? label.trim() : '';
    description = description ? description.trim() : '';
    // description is not required
    if (label) {
      addOptionToCurrentDecision({ label, description });
      setNewOptionData({});
    }
  };

  const onSelectUser = user => {
    // console.log('selected', user);
    const label = user.label.split('(')[0].trim();
    let description = user.desc || user.description;
    description += ' \n https://linkedin.com/in/' + user.username;
    const _id = user._id;
    const data = { _id };
    // console.log('submitting', { label, description, data });
    addOptionToCurrentDecision({ label, description, data });
    setNewOptionData({});
    // TBD: the target's respect vector should be updated to reflect this. Could be done by scheduled task
  };

  // {/*cloud.length > 0 && <SimpleCloud data={cloud} />*/}
  const isPeopleType = currentDecision?.question?.data?.type == 'people';
  return (
    <Panel min={minWidth} max={maxWidth}>
      {currentDecision && (
        <div>
          <Heading as='h1' size={size} textAlign='left'>
            {currentDecision?.question?.data?.title || 'no title'}
          </Heading>
          <Text fontStyle='italic'>{currentDecision?.question.data.description}</Text>
          <Divider />

          {options?.length ? (
            <>
              <Heading size='sm' mb='0.5em'>
                Select your preferred options:
              </Heading>
              <HStack
                sx={{ backgroundColor: '#eee', paddingLeft: '10px', border: '1px solid lightgray' }}
              >
                <Text sx={{ width: '30%' }}>Sort by</Text>
                <SortSelector sx={{ width: '100px' }} onChange={onSortChange} />
              </HStack>

              {options
                .sort(multiSort(sortOptions[sortOrder].split(',')))
                // .reverse()
                .map((option, idx) => {
                  const title = getOptionTitle(option);
                  const optionVote =
                    getOptionVote({ decision: currentDecision, option }) / maxAnswer;
                  // temp, this will help filter out the -1 votes from activating the "-" button before
                  const hasPositiveVote = optionVote > 0;
                  return (
                    <HStack
                      key={idx}
                      alignItems='top'
                      pointerEvents={true}
                      cursor='pointer'
                      _hover={{
                        backgroundColor: currentOptionId === option._id ? 'inherit' : '#f6f6f6',
                        borderTopRightRadius: currentOptionId === option._id ? '' : '20px',
                        borderBottomRightRadius: currentOptionId === option._id ? '' : '20px'
                      }}
                    >
                      <Tooltip label={disabled ? 'Sorry, you are not a member of this group' : ''}>
                        <HStack
                          width='100%'
                          borderBottom='1px solid #eee'
                          pt='6px'
                          pb='6px'
                          flex='1'
                        >
                          <VoteTotalBox
                            maxChars={maxCharsInOptionTotals}
                            isHighlighted={hasPositiveVote}
                          >
                            {truncate_precision(option.total)}
                          </VoteTotalBox>

                          <Box flex='1' onClick={() => loadOptionData(option._id)}>
                            <Tooltip label={option.data.description}>
                              <Text as='span' fontSize='1.2em'>
                                {title || 'no title'}
                              </Text>
                            </Tooltip>
                          </Box>
                          <Box width={['30%', '20%']} height='24px' pr='8px'>
                            {hasPositiveVote && (
                              <OptionSlider
                                option={option}
                                voteValue={optionVote}
                                onUpdate={handleSliderChange}
                              />
                            )}
                          </Box>

                          <Checkbox
                            onChange={event => handleCheckboxVote(event, option)}
                            name={option._id}
                            isDisabled={disabled}
                            isChecked={hasPositiveVote}
                            value={hasPositiveVote ? 1 : 0}
                            borderColor='#666'
                          />

                          <Box width='22px' height='22px'>
                            {currentOptionId === option._id ? (
                              <FontAwesomeLoader size='lg' color='#6be300' icon={['fad', 'gear']} />
                            ) : null}
                          </Box>
                        </HStack>
                      </Tooltip>
                      {useDrawer && (
                        <DetailsDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                      )}
                    </HStack>
                  );
                })}
            </>
          ) : null}
          <Tooltip label={disabled ? 'Not a voting member' : ''}>
            <Box backgroundColor='cyan.700' borderRadius='12px' px='1em' py='0.5em' mt='1em'>
              <Heading as='h3' size='md' fontWeight='normal' color='cyan.200'>
                {qprompt}
              </Heading>
              {isPeopleType && (
                <Box sx={{ backgroundColor: 'white' }}>
                  <PeopleSelector
                    onSelect={onSelectUser}
                    inputPlaceholder={'Name as you know them...'}
                    label={'Search by name'}
                  />
                  <Text>or enter by hand &#8964;</Text>
                </Box>
              )}
              <VStack mt='8px' align='start' maxWidth='300px'>
                <Input
                  type='text'
                  name='label'
                  size='xs'
                  onChange={updateNewOptionData}
                  value={newOptionData?.label || ''}
                  placeholder={isPeopleType ? 'Their name' : 'Title of suggestion'}
                  color='cyan.900'
                  backgroundColor='white'
                  borderColor='cyan.900'
                  isDisabled={disabled}
                />
                <Input
                  type='text'
                  name='description'
                  size='xs'
                  onChange={updateNewOptionData}
                  value={newOptionData?.description || ''}
                  placeholder={
                    isPeopleType ? 'About them, including links' : 'Description of suggestion'
                  }
                  color='cyan.900'
                  backgroundColor='white'
                  borderColor='cyan.900'
                  isDisabled={disabled}
                />
                <Button
                  onClick={handleAddOption}
                  disabled={isActive || disabled}
                  size='xs'
                  color='white'
                  backgroundColor='cyan.400'
                  _hover={{
                    color: 'cyan.400',
                    backgroundColor: 'cyan.200'
                  }}
                >
                  {'Submit'}
                </Button>
              </VStack>
            </Box>
          </Tooltip>
        </div>
      )}
    </Panel>
  );
};

export default OptionsList;
