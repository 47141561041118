import React, { useState } from 'react';
import {
  Flex,
  Box,
  FormLabel,
  HStack,
  Input,
  Stack,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  Select
} from '@chakra-ui/react';
import { faGameConsoleHandheld } from '@fortawesome/pro-solid-svg-icons';

import { edgeTypesForUX } from 'redux/actions/peopleNetwork';

const Controls = props => {
  const {
    onFieldUpdate,
    // claimedOnly,
    // toggleClaimed,
    changeMax,
    maxNodes,
    direction,
    setDirection
  } = props;

  return (
    <Flex backgroundColor='cyan.900' color='cyan.200' borderRadius='0.5em'>
      <Box sx={{ width: '50%' }}>
        <HStack>
          <FormLabel fontSize='sm' m='10px'>
            Edge Direction
          </FormLabel>
          <RadioGroup onChange={setDirection} value={direction}>
            <Stack direction='row'>
              <Radio value='in'>In</Radio>
              <Radio value='out'>Out</Radio>
              <Radio value='both'>Both</Radio>
            </Stack>
          </RadioGroup>
        </HStack>
      </Box>
      <Box sx={{ width: '50%' }}>
        <HStack>
          <FormLabel fontSize='sm' m='10px'>
            Max nodes
          </FormLabel>
          <Input
            type='text'
            id='max'
            name='max'
            placeholder={maxNodes}
            size={4}
            onChange={onFieldUpdate}
            onBlur={changeMax}
            backgroundColor='#eee'
            width='100px'
          />
        </HStack>
      </Box>
    </Flex>
  );
};

export const SocialControls = props => {
  const { onFieldUpdate, requiredSocial, setRequiredSocial } = props;
  return (
    <Flex backgroundColor='cyan.900' color='cyan.200' borderRadius='0.5em'>
      <Box sx={{ width: '50%' }}>
        <HStack>
          <FormLabel fontSize='sm' m='10px'>
            Require
          </FormLabel>
          <RadioGroup onChange={setRequiredSocial} value={requiredSocial}>
            <Stack direction='row'>
              <Radio value='linkedin'>Linkedin</Radio>
              <Radio value='twitter'>Twitter</Radio>
              <Radio value='mastodon'>Mastodon</Radio>
            </Stack>
          </RadioGroup>
        </HStack>
      </Box>
    </Flex>
  );
};

export const EdgeControls = props => {
  let { selected, onChange } = props;
  const types = edgeTypesForUX;
  const sel = selected?.length > 0 ? selected : ['LI', 'RESPECT']; // default to just 2 // types
  const [localChecked, setLocalChecked] = useState(new Set(sel));
  const onCheck = event => {
    let idd = event.target.value;
    let onOff = event.target.checked;
    let current = new Set(localChecked);
    if (onOff) {
      current.add(idd);
    } else {
      current.delete(idd);
    }
    setLocalChecked(current);
  };

  const onSubmit = () => {
    onChange(localChecked);
  };

  const checkboxes = types
    .map(typ => {
      const idd = typ;
      const lookup = { LI: 'linkedin', RESPECT: 'respect' };
      const nm = lookup[typ] || typ.toLowerCase();
      return (
        nm && (
          <Checkbox
            onChange={onCheck}
            name={'types'}
            isChecked={localChecked.has(idd)}
            key={idd}
            value={idd}
          >
            {nm}
          </Checkbox>
        )
      );
    })
    .filter(checkbox => !!checkbox);

  return (
    <Flex backgroundColor='cyan.900' color='cyan.200' borderRadius='0.5em'>
      <Box sx={{ width: '50%' }}>
        <HStack>
          <FormLabel fontSize='sm' m='10px'>
            Link Types
          </FormLabel>
          <Stack direction='row'>{checkboxes}</Stack>
          <Button color={'#444'} onClick={onSubmit}>
            Go
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
};

export const ToolsSelector = props => {
  const { tools, onChange, current } = props;
  return (
    <Select
      onChange={onChange}
      padding={'1px '}
      sx={{ backgroundColor: '#fff', height: '30px', paddingTop: '1px' }}
    >
      {Object.entries(tools).map((pair, i) => {
        return (
          <option value={pair[0]} placeholder={'choose one'} key={i}>
            {pair[0]}
          </option>
        );
      })}
    </Select>
  );
};

export default Controls;
