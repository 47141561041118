import React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useHistory, useParams } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import {
  Text,
  Heading,
  Box,
  Flex,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link
} from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';

const GreencheckAbout = () => {
  const { googleUser: user, nooUser, networks, currentNetworkData } = useNoo('Splash');
  const network = currentNetworkData;

  const history = useHistory();
  var params = new URLSearchParams(useLocation().search);
  let index = params.get('index');
  index = index ? parseInt(index) : null;

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };
  let headers = [];
  let bodies = [];
  const header0 = `What is GreenCheck`;
  const body0 = `
Proof-of-humanity as [Vitalik Buterin](https://www.youtube.com/shorts/WH83_Fad7E4) defines it: 
  > “I certify that I am a real human and I am registered exactly once in this registry”

  GreenCheck is a ***by-invitation mutual validation network***, to be formalized as a ***network cooperative***.
  
  It provides a simple, blockchain-free way to assert one's personhood/humanity, leveraging what we know about each other. Who knows best whether to trust my online identifiers? My friends.

Hence, ***three-friend authentication***.

GreenCheck is also a tool for ***human stigmergy***. We *mark* (praise) each other to facilitate  *people discovery*. See [StigPeople](https://www.notion.so/m4co/Stig-People-719a5704f0934f14a53309bca56d368d). 

Greencheck is blockchain-independent, but web3-friendly.
  

`;
  headers.push(header0);
  bodies.push(body0);
  const header1 = `Mission`;
  const body1 = `
To manifest a large, resilient, flexible, useful trust commons out of existing knowledge we have of each other, and to collectively exploit it for mutual value.

GreenCheck's intent is to help provide critical infrastructure for the emergence of trust networks that can act as ***networked autonomous organisms ([NAO](https://nao.is/))*** that are application-independent, govern themselves, capture the collective value of their participants, and can compose and federate at scale.

`;
  headers.push(header1);
  bodies.push(body1);
  const header2 = `Assumptions`;
  const body2 = `
* Digital identity is a hard problem
* Humans have many uses for different kinds of identifiers
	* Pseudonymity, anonymity, legally binding, proof of humanity…
* No single identifier will ‘rule them all’
* Numerous applications, however, require a registry that can reliably assert  one-human/one-id 
* Identity is 'generative'; who we are generates from our relationships with others.

`;
  headers.push(header2);
  bodies.push(body2);
  const header3 = `Problems GreenCheck Solves`;
  const body3 = `
GreenCheck solves multiple related problems: 

* **Proliferation of identifiers**
	* Different, non-interoperable IDs across many apps
	* Single sign-on
* **Proof of humanity**
	* Is this account a human?
* **One human/one identifier**
	* Does this human only have one such account in this community, e.g. for voting?
* [**Sybil**](https://en.wikipedia.org/wiki/Sybil_attack) and [**collusion resistance**](https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4311507) 
	* Can bad actors flood the zone with fakes?
* **Reputation** and the [**blue checkmark problem**](https://medium.com/@yonatanzunger/the-hard-lessons-of-blue-checkmarks-c12094ed4a9c) 
	* How to establish reputation across applications
	* We can't rely on central authorities for validation
* **Portable Communities**
    * To be a member of multiple communities, one needs an consistent identifier

This approach isn't perfect, but because it is invitation-only, everyone is accountable to those who invited them, and we can create something very useful and valuable and co-invest in realizing its full potential.

`;
  headers.push(header3);
  bodies.push(body3);
  const header4 = `Key aspects`;
  const body4 = `
  
  * Proof of personhood
	* For voting, universal basic income, reputation, portable communities, sybil and collusion resistance,...
* Three-friend Authentication
	* We validate each others' claims
* People Discovery via Human Stigmergy
  * We praise (mark) each other with domains of expertise.
* Networks of Trust
	* Created by validations, praises, and other relationships
	* Comparable to cryptograph *[webs of trust](https://en.wikipedia.org/wiki/Web_of_trust)*
* Generative identity
	* Our identity is arises from the totality of our relationships
* Federated authentication
	* Decentralized single-sign-on using decentralized identifiers ([DIDs](https://www.w3.org/TR/did-core/))
Proof of humanity/personhood is critical for many applications online: voting, universal basic income, reputation, portable communities, etc.


`;
  headers.push(header4);
  bodies.push(body4);
  const header5 = `Portable Communities`;
  const body5 = `
The lack of a shared way of representing and composing online communities is a critical limiter on the agency of prosocial movements. 

GreenCheck provides a way to represent individuals unambiguously across multiple communities.

`;
  headers.push(header5);
  bodies.push(body5);
  const header6 = `Network Cooperative`;
  const body6 = `
GreenCheck is a social graph commons. Every subnetwork is effectively a community: fractal, composable, federable. 

It will be a legal cooperative, governed using the innate capacity for liquid democracy that comes with networks. Traditional cooperative structures are not able to take advantage of that. 

Thus, such a social graph commons requires new governance structures that empower individual subnetworks to govern themselves and create and derive value, while simultaneously contributing to the whole. We're not sure yet how to do that, but we take inspiration from [RenDanHeYi](https://www.rendanheyi.com/about), pioneered by [Haier](https://www.corporate-rebels.com/blog/haier), which re-organized from a traditionally hierarchical  top-down management structure to networks of self-managing entrepreneurial microenterprises.

Every GreenCheck user event has a timestamp. Thus every eventual cooperative member can be commensurately rewarded with reputation, governance participation, financial value, and any other shared outcomes.

We are working with [Start.Coop](https://www.start.coop/) and [legal advisors](https://jrwiener.com/) and others to develop a basic but flexible legal structure suitable to the task.



`;
  headers.push(header6);
  bodies.push(body6);
  const header7 = `How it works`;
  const body7 = `
Users claim attributes they can prove they control (e.g. email, phone, social accounts), and validate the claims of others they know and trust. This creates ***trust graphs*** with built-in accountability and metrics of reliability, and many uses.

* Log in via email. This creates a node in the trust graph.
* Claim attributes you can prove you control, e.g. LinkedIn, Twitter, Github, Mastodon, Youtube... accounts.
* Validate the claims of others you know. This creates trust edges in the graph. 
* Invite others you trust.
* You own and control your node and outbound edges (from you to others.)

`;
  headers.push(header7);
  bodies.push(body7);
  const header8 = `Token economics`;
  const body8 = `

The structure and evolution of the GreenCheck trust graphs are natural for token economics. Order of invitation and validation, network centrality, etc. can easily map to token issuance. 

Rather than commit to specific token logic now, GreenCheck will use the edges of the trust graph as delegations for [liquid democratic decisions](https://en.wikipedia.org/wiki/Liquid_democracy) about how tokens should work.

`;
  headers.push(header8);
  bodies.push(body8);
  const header9 = `Why participate?`;
  const body9 = `
Several reasons:

1. You get a **unique decentralized identifier ([DID](https://en.wikipedia.org/wiki/Decentralized_identifier))** that can be used anywhere to represent the one and only you.
2. You **validate others** you know, strengthening their GreenCheck.
3. You make knowledge about people that's currently only in your head available as part of a **shared resource for collective intelligence**.
4. You **earn a share** of the collective value created and derived by that shared resource.

`;
  headers.push(header9);
  bodies.push(body9);
  const header10 = `Measuring Trust`;
  const body10 = `
Network analytics are our friends. I can compare my subnet to yours and get a *distance metric* that tells me how much we share in common, hence how *good* you are from my perspective.

If bad behaviors collude to invite and validate each other, they can be detected by others because their *cluster* is disjoint from the main network.

`;
  headers.push(header10);
  bodies.push(body10);

  const header12 = `Known Issues`;
  const body12 = `
1. People have multiple nodes, e.g. the email-based one they registered with, a LinkedIn node resulting from connections someone else imported, ...
2. Those nodes are unconnected until the email one claims the others. That can create ambiguity, which we are endeavoring to minimize.
3. Regular email login (non-Google) has no two-factor authentication yet.
`;
  headers.push(header12);
  bodies.push(body12);

  const header13 = `Data Schemas`;
  const body13 = `


### NooNAO Data Schemas

The primary motivation of NooNAO is the formation of ***by-invitation networks of mutual trust, respect, and expertise***.

#### ArangoDB
  
In support of that mission, our underlying database is [ArangoDB](https://www.arangodb.com/), a best of class [graph database](https://en.wikipedia.org/wiki/Graph_database).

Arango has a notion of a *[deployment](https://docs.arangodb.com/3.12/arangograph/deployments/)*, which is essentially a server configuration with a specific set of capacities (e.g. storage, processors, ...)

Each deployment can have multiple *databases*, each of which can have an arbitrary set of *collections*. For instance, one *database* might be for testing, another for production, a third for a customer-specific private label, ...

NooNAO's work since January, 2021 has been on a single deployment hosted by Arango, with six structurally equivalent databases. We have a *pro bono* Arango deployment donated by a supercomputer center in Finland that can scale arbitrarily large at no cost until we are ready.


#### NooNAO standard database

Since graphs are networks of *nodes* connected by *edges*, the standard NooNAO configuration is a set of collections that are either *[node](https://docs.arangodb.com/3.11/concepts/data-structure/collections/)*  or *[edge](https://docs.arangodb.com/3.11/graphs/working-with-edges/)* collections. 

The primary instances of **node collections** are: *Persons, Documents, Resources*, and *Questions*. **Edge collections** store directed relationships between those, e.g. *PersonA (source) respects PersonB (target) in domains [...]*, or *PersonA recommends DocumentZ*.

All such *entities* have simple schema specifying a few allowable fields, and a *data* field that allows for free-form addition of relevant information without schema changes. Any other top level fields are ignored, unless/until added explicitly. (See Protocol Buffers below.)

For instance, *edges* have a required field *type*, which must come from a finite set of standard values, e.g. edges of type *has_member, owns, invites, respects, linkedin, twitter...*. 

The *data* field of an edge of type *respects* is a dictionary keyed by domains of expertise, whose values are the level of respect (-1 to 1) for each domain that the source of the edge has for the target. Effectively, it is a *multi-dimensional floating point respect vector* from the source to the target. That is not built into the schema, because it is within the *data* field.

#### Python API

There is a Python API server that allows [CRUD](https://en.wikipedia.org/wiki/Create,_read,_update_and_delete) operations, and more high-level combinations thereof, on Arango collections. Primarily, that means **adding and deleting** nodes and edges, **qualifying/updating** them, and **search**.  

#### Javascript Front-end and Middleware
Several NooNAO example applications exist that share a common ReactJS framework and NodeJS middleware, and have access to the Python API:

* [NAO.is](https://nao.is/) - overview and network search
* [BestOfNow](https://bestofnow.net) - social bookmarking
* [GreenCheck](https://greencheck.world/) - proof of personhood and people marking
* [WhoKnows](https://whoknows.noo.network/) - expertise mapping
* [Emerge](https://emerge.noo.network/) - private label for a conference; inquiries/decisioning


That framework is very flexible for spinning up prototype applications, new search and visualization approaches, etc.

#### Data Schemas and Protobuf 

We use Protocol Buffers for defining the basic schemas of the various entities in our system: *nodes, edges, people, groups, decisions, documents*, etc. This provides [a lot of benefits](https://codeclimate.com/blog/choose-protocol-buffers), especially flexibility in deployment, and ease of changes across the full stack.
 
See the [Protobuf for the NooNAO server](https://standingwave.net/data/noonetwork.proto).

Let's look at an example. A Node is defined as:

~~~
message Node {
  oneof node {
    Person person = 1;
    Group group = 2;
    Listing listing = 3;
    Image image = 4;
    Document document = 5;
    Question question = 6;
    Option option = 7;
    Answer answer = 8;
  }
}
~~~
That means it can be an instance of one of those entities. So what is a Person?

~~~
message Person {
  oneof opt1 {
    // arango generated uuid
    string _id = 1;
  }
  oneof opt2 {
    google.protobuf.Timestamp createDate = 2;
  }
  oneof opt3 {
    google.protobuf.Timestamp modDate = 3;
  }
  oneof opt4 {
    // deleted keeps the node in the graph (content should be erased) so that edges aren't deleted
    // have not actually used this feature so far
    bool deleted = 4;
  }
  oneof opt5 {
    // if true, node was created for an invitation not yet accepted
    bool provisional = 5;
  }
  oneof opt6 {
    google.protobuf.Struct data = 6;
  }
}
~~~
That says that a Person can have fields with types: *_id (string), createDate (timestamp), modDate (timestamp), deleted (boolean), provisional (boolean)* or *data (dictionary)*.

How about an Edge?

~~~
message Edge {
  oneof opt1 {
    string _id = 1;
  }
  // from node id: fieldname is what arango uses
  string _from = 2;
  // to node id: fieldname is what arango uses
  string _to = 3;


  Type type = 4;
  oneof opt5 {
    string label = 5;
  }
  oneof opt6 {
    google.protobuf.Timestamp createDate = 6;
  }
  oneof opt7 {
    google.protobuf.Timestamp modDate = 7;
  }

  // true = created for an invitation not yet accepted
  bool provisional = 8;

  oneof opt9 {
    // some edge types, such as INVITES, require a group context
    string groupId = 9;
  }
  oneof opt10 {
    google.protobuf.Struct data = 10;
  }
}
~~~
That says that an Edge can have some fields in common with Node, plus:  *_from (string), _to (string), type (Type), label (string)*, or *groupId*. 

But what is Type?

~~~
  enum Type {
    NONE = 0;
    // Person to Person:
    INVITES = 1;
    // Group to Person:
    HAS_MEMBER = 2;
    // _from must be a Person, _to can be anything but a Person or a Group, it is exclusive
    OWNS = 3;
    // Person to Person:
    VOUCHES_FOR = 4;
    // Person to Person
    REPORTS_TO = 5;
    // Group to Group
    IS_PARENT_OF_G = 6;
    // _from must be a Group; _to can be Document, Image, Question
    G_OWNS = 7;
    // Question to Option
    Q_OWNS_O = 8;
    // Person to resources
    RECOMMENDS = 9;
    // Question to Question
    IS_PARENT_OF_Q = 10;
    // Person to Group (not exclusive)
    P_OWNS_G = 11;
    // Question to Answer
    Q_OWNS_A = 12;
    // Imported from service
    LI = 13;
    // Imported from another service
    FB = 14;
    // Imported from a third service
    TW = 15;
    // Respect vector
    RESPECT = 16;
    // Social account or other attribute claimed
    CLAIM = 17;
    // Validates another account
    VALIDATE = 18;
    // Follow
    FOLLOW = 19;
  }
~~~
That says a field of type Type can have one of 20 named values (e.g. *HAS_MEMBER*), each of which equates to an ordinal number 0-19.







  `;
  headers.push(header13);
  bodies.push(body13);

  const header11 = `Who`;
  const body11 = `
GreenCheck is an initiative of [NAO.is](https://nao.is/) in collaboration with the [Collaborative Technology Alliance](https://www.collaborative.tech/), [JLinc](https://www.jlinc.com/), and others.
`;
  headers.push(header11);
  bodies.push(body11);

  const oneAccordion = (header, body) => {
    return (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text fontSize='lg' fontWeight={'bold'}>
                {header}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <RenderChakraMarkdown key='mkdown' fontSize='2px'>
            {body}
          </RenderChakraMarkdown>
        </AccordionPanel>
      </AccordionItem>
    );
  };

  var items = [];
  let k = 0;
  headers.forEach(header => {
    const body = bodies[k];
    items.push(oneAccordion(header, body));
    k += 1;
  });
  console.log('index', index);

  const tagline = network?.tagline; // || 'Welcome to ' + display;
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      margin='1em auto'
      width='90vw'
      borderRadius='lg'
      boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
      padding='1em'
      bgColor='splashBg'
    >
      {tagline && (
        <Heading as='h2' size='md'>
          {tagline}
        </Heading>
      )}

      <VStack>
        {' '}
        <Accordion
          className='accordion'
          defaultIndex={index}
          allowToggle
          width={['90vw', '82vw', '60vw']}
        >
          {items}
        </Accordion>
      </VStack>
    </Flex>
  );
};

export default GreencheckAbout;
