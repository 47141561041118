import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  VStack,
  Heading,
  HStack,
  Spinner,
  Text,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { ContentSubpanel } from 'components/Segments/Styled';
import UserAuth from 'components/User/UserAuth';
import OneEarthSimpleMap from 'components/DataViz/OneEarthSimpleMap';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import NetworkTabs from 'components/Networks/NetworkTabs';
import useNoo from 'hooks/useNoo';
import useBioregionData from 'hooks/useBioregionData';
import { intersect, unit_test_intersect } from 'utils';
import OptionsList from 'components/Decisions/OptionsList';
import RegionData from './RegionData';

const Instructions = props => {
  return (
    <Accordion
      backgroundColor={'#eee'}
      allowToggle
      /* width={width}
  onChange={onChange} */
    >
      {' '}
      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text fontSize='md' fontWeight={'bold'}>
                Instructions
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <Text sx={{ border: '1px solid gray', p: '5px' }}>
            The goal is to Nominate high quality people in Realms, SubRealms, and Bioregions,
            especially in the S. America, Africa, IndoMalaya, and Oceania realms.
          </Text>
          <Accordion allowToggle>
            {' '}
            <AccordionItem>
              <Heading as='h2'>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Text fontSize='md' fontWeight={'bold'}>
                      Map
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb={4}>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  <b>Set level of detail</b> with the radio buttons below the map. Subrealm level is
                  ideal to start.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  <b>Choose region</b> by clicking colored areas. People interface will appear on
                  the right.
                </Text>

                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  <b>Drag and zoom</b> the map. On Desktop, mouse or trackpad forward / back to
                  zoom.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <Heading as='h2'>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Text fontSize='md' fontWeight={'bold'}>
                      People
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb={4}>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Toggle between the <b>Find People</b> and <b>Rank Nominations</b> tabs on the
                  right.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Use <b>Find People</b> tab to search for people in the selected region. Some
                  regions have too many to show effectively without filtering.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  <b>Filter results</b> by their adjacency to you or someone else, by text in their
                  profiles.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Use the <b>Rank Nominations</b> tab to see who has been nominated so far, and to
                  upvote anyone you know and respect.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <Heading as='h2'>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Text fontSize='md' fontWeight={'bold'}>
                      Nominating
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb={4}>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Every person found below the map can be clicked on to open a dialog.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Click <b>Nominate</b>. An interface will appear with their bioregion automatically
                  filled in. Click <b>Add bioregion...</b> to &apos;mark&apos; them.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Optionally, choose other regions they know from the search bar, and click{' '}
                  <b>Add bioregion...</b>.
                </Text>
                <Text sx={{ border: '1px solid gray', p: '5px' }}>
                  Select <b>Climate Solutions</b> to add domains of expertise.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const BioregionsModule = () => {
  const [taxo, setTaxo] = React.useState('bioregions');
  const [label, setLabel] = useState('');
  const [level, setLevel] = useState(null);
  const [currentBios, setCurrentBios] = useState([]);
  const [bio, setBio] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    const w = mapRef.current.offsetWidth; // window.innerWidth
    setWindowWidth(w);
  };
  const mapRef = useRef(null);
  const { nooUser, currentNetworkData } = useNoo('BioregionsModule');
  const network = currentNetworkData;
  const netname = network.id;
  const [center, setCenter] = useState(nooUser?.person);
  const [filterUser, setFilterUser] = useState(true);
  const _MAX_MATCHES = 20000;
  const [totalMatched, setTotalMatched] = useState(null);
  const [justLoaded, setJustLoaded] = useState(true);

  const [lookupBios, setLookupBios] = useState({});
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    getBioregion,
    totalMatches
  } = usePeopleNetwork();

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);
  const mapWidth = windowWidth * 0.6;

  const {
    all_93,
    all_93_isFetching,
    all_94_185,
    all_94_185_isFetching,
    land_50m,
    land_50m_isFetching
  } = useBioregionData();

  const bioregionsFetching = all_93_isFetching || all_94_185_isFetching || land_50m_isFetching;

  useEffect(() => {
    if (totalMatches > _MAX_MATCHES) {
      alert(
        `There are ${totalMatches} matches for that search, but results are limited to ${_MAX_MATCHES}, and are thus incomplete. Filter by user and/or text to narrow the search.`
      );
    }
  }, [totalMatched, totalMatches]);

  const bioregions = useMemo(() => {
    if (all_93 && all_94_185) {
      const all_bios = [...all_93, ...all_94_185];
      let lookup_bios = {};
      // TBD: NB: Only Central America is both a realm and subrealm. must deal
      let relms = new Set();
      let subrelms = new Set();
      all_bios.forEach(bio => {
        const meta = bio.meta;
        const relm = meta?.realm;
        const subrelm = meta?.subrealm;
        if (relm) {
          relm in lookup_bios || (lookup_bios[relm] = new Set());
          lookup_bios[relm].add(bio.id);
          relms.add(relm);
        }
        if (subrelm) {
          subrelm in lookup_bios || (lookup_bios[subrelm] = new Set());
          lookup_bios[subrelm].add(bio.id);
          subrelms.add(subrelm);
        }
      });
      Object.entries(lookup_bios).forEach(([key, value]) => {
        lookup_bios[key] = Array.from(value);
      });

      setLookupBios(lookup_bios);
      return all_bios;
    }
    return [];
  }, [all_93, all_94_185]);

  const onMapClick = (geo, level) => {
    const { name, meta } = geo;
    const mapper = { bioregion: name, subrealm: meta.subrealm, realm: meta.realm };
    const label = mapper[level];
    const idd = level == 'bioregion' ? geo.id : label;
    setLabel(label);
    setBio({ name: label });
    setLevel(level);
    setCurrentBios(lookupBios[idd] || [idd]);
  };
  const onMapHover = (label, on_off) => {
    // console.log('geo', geo, 'on_off', on_off);
  };

  const onCallBioregionSearch = useCallback(
    args => {
      const { center, filterUser, textFilter, filterText } = args;
      console.log('curr', currentBios);
      setCenter(center);
      setFilterUser(filterUser);
      setJustLoaded(false);
      if (!fetching && currentBios) {
        const idd = filterUser ? center?._id : null;
        getBioregion({
          bio_ids: currentBios,
          person_id: idd,
          limit: 300,
          max_people: _MAX_MATCHES,
          filters: textFilter
        });
      }
    },
    [fetching, getBioregion, currentBios]
  );

  const onChangeUser = e => {
    console.log('change', e);
  };

  return (
    <Box pb='1em'>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <ContentSubpanel>
            <Text>
              <Text sx={{ fontWeight: 'bold', display: 'inline', fontSize: 'xl' }}>Mission</Text>:
              Highlight, amplify, connect with people doing regenerative work among the{' '}
              <Link href={'https://en.wikipedia.org/wiki/Global_majority'} isExternal>
                <b>Global Majority</b>
                <ExternalLinkIcon mx='2px' />
              </Link>
              .
            </Text>
            <Text>
              Focusing initially on the{' '}
              <Text sx={{ fontWeight: 'bold', display: 'inline' }}>bioregions</Text> of{' '}
              <b>Central and South America</b> (610M), <b>Africa</b> (1.2B), <b>Indomalaya</b>{' '}
              (2.33B), and <b>Oceania</b> (45M).
            </Text>
            <HStack ref={mapRef} sx={{ gap: '0px', w: '100%' }} alignItems={'flex-start'}>
              <Box sx={{ border: '1px solid lightgray', width: mapWidth + 2 }}>
                {bioregionsFetching && (
                  <Box>
                    <Spinner />
                  </Box>
                )}

                <Instructions />
                <OneEarthSimpleMap
                  bioregions={bioregions}
                  land={land_50m}
                  which={taxo}
                  width={mapWidth}
                  onClick={onMapClick}
                  onHover={onMapHover}
                />
              </Box>
              {bio && (
                <Box width={windowWidth * 0.4 + 'px'}>
                  <RegionData
                    node={bio}
                    network={netname}
                    onSubmit={onCallBioregionSearch}
                    center={center}
                    fetching={fetching}
                  />
                </Box>
              )}
            </HStack>
            {!justLoaded && (
              <NetworkTabs graph={graph} maxNodes={200} default_tab={1} total={totalMatches} />
            )}
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default BioregionsModule;
